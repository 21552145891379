import React from "react";
import { About } from "../ComponentPage/about";
import Contact from "../Components/contact";

export const AboutPage = () => {
  return (
    <>
      <About heading="Über mich" />  
    </>
  );
};
